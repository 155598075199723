import React, { useEffect } from 'react';
import './App.css';
import { motion, useAnimation } from 'framer-motion';
import { FaInstagram, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';

const ProjectCard = ({ project, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.a
      ref={ref}
      href={project.url}
      target="_blank"
      rel="noopener noreferrer"
      className="project-card"
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={controls}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      whileHover={{
        scale: 1.05,
        boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
      }}
    >
      <img src={project.image} alt={`${project.title} project`} className="project-image" />
      <div className="project-info">
        <h3 className="project-title">{project.title}</h3>
        <p className="project-description">{project.description}</p>
      </div>
    </motion.a>
  );
};


function App() {
  const handleMagicClick = () => {
    window.location.href = 'mailto:adam@aisupertutor.org?subject=Collaboration%20Opportunities';
  };

  const projectData = [
    {
      image: "/bc.png",
      title: "Supertutor",
      description: "Built web application with AI tutoring, custom meme generator, multiplayer games, and more",
      url: "https://supertutorai.co"
    },
    {
      image: "/tuttoapp.png",
      title: "tuttO game",
      description: "Built tuttO, the hottest new party game. Players take turns coming up with the flirtiest way to turn on the O master and outflirt the flirtbot",
      url: "https://tuttoplay.com/"
    },
    {
      image: "/b.png",
      title: "Big Signal",
      description: "Built iOS personal safety app summoning help from your designated contacts simultaneously with 1 click",
      url: "https://apps.apple.com/kg/app/big-signal-personal-safety/id6472224823"
    },
    {
      image: "/h.png",
      title: "Happy Thoughts",
      description: "Built iOS positive affirmations app sending you daily happy thoughts",
      url: "https://apps.apple.com/us/app/happy-thoughts/id6476699027"
    },
    {
      image: "/t.png",
      title: "Tesla",
      description: "Worked as top performing autopilot data specialist averaging 300% higher efficiency and 30% higher accuracy than median",
      url: "https://www.tesla.com/autopilot"
    },
    {
      image: "/n.png",
      title: "Northwell Health",
      description: "Designed bespoke interactive 'art as a form of healing' installations for Monter Cancer Center",
      url: "https://cancer.northwell.edu/cancer-care/locations/center-for-advanced-medicine/rj-zuckerberg-cancer-center"
    },
    {
      image: "/primary.png",
      title: "Primary VC",
      description: "Created designs and marketing for Primary NYC Summit with over 2000 top VCs & Founders",
      url: "https://www.primary.vc/"
    }
  ];

  return (
    <div className="App">
      <div className="animated-bg"></div>
      <header className="header">
        <motion.h1 
          className="studio-name"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Adam Jonah Studio
        </motion.h1>
      </header>
      <main>
        <section className="hero">
          <motion.h2 
            className="hero-title"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Bringing Creative <br />
            Technology <br /> 
            <span className="glow-text">to Life</span>
          </motion.h2>
          <motion.button
            className="cta-button"
            onClick={handleMagicClick}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            Let's Collaborate
          </motion.button>
        </section>

        <section className="portfolio">
          <motion.h2 
            className="section-title"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Portfolio of Positive Impact
          </motion.h2>
          <div className="project-grid">
            {projectData.map((project, index) => (
              <ProjectCard key={index} project={project} index={index} />
            ))}
          </div>
        </section>

        <section className="connect">
          <motion.h2 
            className="section-title"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Let's Connect
          </motion.h2>
          <div className="social-container">
            {[
              { icon: FaInstagram, url: "https://www.instagram.com/adamjonah_", label: "Instagram" },
              { icon: FaLinkedin, url: "https://www.linkedin.com/in/adamjonah/", label: "LinkedIn" },
              { icon: FaEnvelope, url: "mailto:adam@aisupertutor.org", label: "Email" }
            ].map((social, index) => (
              <motion.a
                key={index}
                href={social.url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={social.label}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <social.icon className="social-icon" />
              </motion.a>
            ))}
          </div>
        </section>
      </main>
      <footer>
        <p>&copy; {new Date().getFullYear()} Adam Jonah Studio. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;